//`CAMBIAR`
//const dominio="https://632491505c1b435727ab8796.mockapi.io/deiby"
// const dominio="https://transporte-publico.herokuapp.com/api"
// const dominio="http://maps.socidocbolivia.site/api"
// const dominio="http://localhost:5006/api" 
// const dominio="http://154.53.60.31:444/api"
const dominio="https://dev-api.mangostreetapp.com/api"
// const dominio="http://192.168.0.103:5006/api" 
// const dominio="http://127.0.0.1:80/api"
// const dominio="http://154.53.60.31:80/api" 
// const dominio=window.location.protocol + "//" + window.location.host+"/api" 

export let urls={
    Login: dominio+"/Auth/login",
    Register: dominio+"/Auth/register",
    Logout: dominio+"/Auth/logout",
    
    Gremial: dominio+"/Association",
    Line: dominio+"/Line",
    UploadImage: dominio+"/Images/upload",
    Route: dominio+"/Route",
    Brand: dominio+"/Brand",
    Model: dominio+"/Model",
    Vehicle: dominio+"/Vehicle",
    Internal: dominio+"/Internal",
    Group: dominio+"/Group",
    GroupLine: dominio+"/GroupLine",
    Travel: dominio+"/Travel",
    Switch: dominio+"/Switch",
    Owner: dominio+"/Owner",
    Location: dominio+"/Location",
    
    DriverGroup: dominio+"/driverGroup",
    DetectedPoint: dominio+"/detectedPoint",
    
    // CalculeWalk: "http://127.0.0.1:5216/Calcule/DijkstraWalk",
    // CalculeBus: "http://127.0.0.1:5216/Calcule/DijkstraBusList",
    // UpdateConvergences: "http://127.0.0.1:5216/Calcule/UpdateConvergences",
    // UpdateDataGlobalsPublicTransport: "http://127.0.0.1:5216/Calcule/UpdateDataGlobalsPublicTransport",
    CalculeWalk: "https://dev-routecalcule.mangostreetapp.com/Calcule/DijkstraWalk",
    CalculeBus: "https://dev-routecalcule.mangostreetapp.com/Calcule/DijkstraBusList",
    UpdateConvergences: "https://dev-routecalcule.mangostreetapp.com/Calcule/UpdateConvergences",
    UpdateDataGlobalsPublicTransport: "https://dev-routecalcule.mangostreetapp.com/Calcule/UpdateDataGlobalsPublicTransport",
    
    Consulte: dominio+"/test/consulte",
}